@import url(https://fonts.googleapis.com/css?family=Dancing+Script|Montserrat:700);
.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.slidea-portfolio-image {
  width: 30%;
  margin-left: 3%;
  margin-top: 1.5rem;
  border-radius: 6px;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: inline-block;
}

.slidea-portfolio-image.slidea-portfolio-image-ready {
  transition: all 0.5s ease;
}

.slidea-portfolio-image.slidea-portfolio-image-ready:hover {
  opacity: 1 !important;
  z-index: 1;
  transform: translateY(-1rem) !important;
}

.slidea-control {
  top: 100% !important;
  margin-top: -108px !important;
}

@media screen and (max-width: 767px) {
  .slidea .margin-4x {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .slidea .padding-4x {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
